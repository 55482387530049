import React, { useCallback } from "react";
import * as action from "../../service/action";

import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
  "pk_test_51QNkN5IkqrJEq7ti6cE05G3XX1BsLp9bj2BkwBUals3XHUIbtO0uGk5HBSRcErtY3t6zC7EA2v7Y4e5eLcJyQtx7000R90B96U"
);

const CheckoutForm = () => {
  const fetchClientSecret = useCallback(async () => {
    // Create a Checkout Session
    const response = await action.createCheckoutSession({
      product: "bappa-platinum",
    });

    return response.data.clientSecret;
    console.log(response);
    // await action
    //   .createCheckoutSession()
    //   .then((res) => res.json())
    //   .then((data) => data.clientSecret);
  }, []);

  const options = { fetchClientSecret };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default CheckoutForm;
