import React, { useState, useEffect, useRef } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Container,
  Menu,
  MenuItem,
  Box,
  Typography,
  Link,
  IconButton,
  Drawer,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import bappaTap from "../../images/bappa-tap.png";
import bappaLogo from "../../images/bappa-logo.png";
import bappaVideo from "../../videos/bappaTap.mp4";
import image6 from "../../images/6.jpg";
import card1 from "../../images/card1.jpg";
import personTap from "../../images/personTap.jpg";
import nfcTap from "../../images/nfcTap.jpg";
import handshake from "../../images/handshake.jpg";
import createCardVideo from "../../videos/createCard.mp4";
import "./Lander.css";
import { motion } from "framer-motion";
import MenuIcon from "@mui/icons-material/Menu";
import { card } from "../../assets/jss/material-dashboard-react";

const Lander = () => {
  const [resourcesAnchor, setResourcesAnchor] = useState(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleResourcesClick = (event) => {
    setResourcesAnchor(event.currentTarget);
  };

  const handleResourcesClose = () => {
    setResourcesAnchor(null);
  };

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backdropFilter: isScrolled ? "blur(10px)" : "none",
          transition: "all 0.3s ease",
          borderBottom: isScrolled
            ? "1px solid rgba(255, 255, 255, 0.1)"
            : "none",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              justifyContent: "space-between",
              height: { xs: "64px", md: "80px" },
              transition: "all 0.3s ease",
              px: { xs: 2, sm: 3, md: 4 },
            }}
          >
            {/* Logo */}
            <Link
              href="/"
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 0,
              }}
            >
              <img
                src={bappaLogo}
                alt="BappaCards"
                style={{
                  height: "24px",
                  width: "auto",
                }}
              />
            </Link>

            {/* Center Navigation */}
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 4,
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
              }}
            >
              <Link
                href="/teams"
                sx={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1rem",
                  fontWeight: 400,
                  opacity: 0.9,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                For Teams
              </Link>

              <Link
                href="/products"
                sx={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1rem",
                  fontWeight: 400,
                  opacity: 0.9,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                Products
              </Link>

              <Link
                href="/pricing"
                sx={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1rem",
                  fontWeight: 400,
                  opacity: 0.9,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                Pricing
              </Link>
            </Box>

            {/* Right Side Buttons */}
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                flexGrow: 0,
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <Link
                  href="/log-in"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "1rem",
                    fontWeight: 400,
                    opacity: 0.9,
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                >
                  Login
                </Link>
                <Button
                  variant="contained"
                  href="/get-started"
                  sx={{
                    bgcolor: "#dc004e",
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: 400,
                    px: 3,
                    py: 1,
                    borderRadius: "8px",
                    "&:hover": {
                      bgcolor: "#0f0f23",
                    },
                  }}
                >
                  Get Started
                </Button>
              </Box>

              {/* Mobile Menu Button */}
              <IconButton
                sx={{
                  display: { xs: "flex", md: "none" },
                  color: "white",
                }}
                onClick={() => setMobileMenuOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Add Mobile Menu Drawer */}
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        PaperProps={{
          sx: {
            width: "100%",
            bgcolor: "rgba(0, 0, 0, 0.95)",
            backdropFilter: "blur(10px)",
            color: "white",
          },
        }}
      >
        {/* ... mobile menu content from previous response ... */}
      </Drawer>

      <Box className="lander-container">
        <Container maxWidth="lg">
          <Box
            className="lander-content"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              gap: { xs: 6, md: 8 },
              pt: { xs: "100px", md: "140px" },
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Box className="lander-text">
              <Typography
                variant="h1"
                className="lander-heading"
                sx={{
                  fontSize: { xs: "3rem", md: "4rem" },
                  fontWeight: "bold",
                  mb: 3,
                }}
              >
                Elevate Every Introduction
                <span className="heading-dot">.</span>
              </Typography>

              <Typography
                variant="h5"
                className="lander-subheading"
                sx={{
                  color: "white",
                  mb: 4,
                }}
              >
                BappaCards is the easiest way to create and share your digital
                business card.
              </Typography>

              <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    backgroundColor: "#dc004e",
                    "&:hover": {
                      backgroundColor: "#0f0f23",
                    },
                  }}
                >
                  Try for free
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{
                    color: "white",
                    borderColor: "white",
                    "&:hover": {
                      borderColor: "white",
                      backgroundColor: "rgba(255,255,255,0.1)",
                    },
                  }}
                >
                  For Teams
                </Button>
              </Box>

              <Box className="feature-list" sx={{ display: "flex", gap: 3 }}>
                {[
                  "30-day free trial",
                  "Smart card included",
                  "Free US & Canada shipping",
                ].map((feature) => (
                  <Box
                    key={feature}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      color: "text.secondary",
                    }}
                  >
                    <span className="checkmark">✓</span>
                    {feature}
                  </Box>
                ))}
              </Box>
            </Box>

            <Box className="lander-image">
              <img src={bappaTap} alt="OVOU Digital Business Card Demo" />
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Add this section after your existing hero section */}
      <Box
        sx={{
          minHeight: "100vh",
          bgcolor: "white",
          color: "#333",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "2.5rem", md: "3.5rem" },
              fontWeight: "bold",
              mb: 2,
              maxWidth: "900px",
              lineHeight: 1.2,
            }}
          >
            Don't just exchange contacts—
            <br />
            build strong connections.
          </Typography>

          <Box
            sx={{
              position: "relative",
              mt: 8,
              height: "600px", // Adjust based on your needs
              bgcolor: "#f5f5f5", // Background for the image/video section
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                zIndex: 2,
                color: "white",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: { xs: "2rem", md: "3rem" },
                  fontWeight: "bold",
                  mb: 2,
                }}
              >
                Step 1
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "2.5rem", md: "4rem" },
                  fontWeight: "bold",
                }}
              >
                Have your custom card created
              </Typography>
            </Box>

            {/* Dark overlay */}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                bgcolor: "rgba(0,0,0,0.4)",
                zIndex: 1,
              }}
            />

            {/* Background image/video */}
            <Box
              component="video"
              autoPlay
              muted
              loop
              playsInline
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                filter: "brightness(0.4)", // Darkens the video
              }}
            >
              <source src={createCardVideo} type="video/mp4" />
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Video Section with Step 2 */}
      <Box
        sx={{
          position: "relative",
          height: { xs: "60vh", md: "100vh" },
          width: "100%",
          overflow: "hidden",
        }}
      >
        {/* Video Background */}
        <Box
          component="video"
          autoPlay
          muted
          loop
          playsInline
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            filter: "brightness(0.4)", // Darkens the video
          }}
        >
          <source src={bappaVideo} type="video/mp4" />
        </Box>

        {/* Pause/Play Button */}
        <Box
          sx={{
            position: "absolute",
            top: "2rem",
            right: "2rem",
            zIndex: 2,
            width: "48px",
            height: "48px",
            borderRadius: "50%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            transition: "all 0.3s ease",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            },
          }}
        >
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            ⏸️
          </motion.div>
        </Box>

        {/* Text Overlay */}
        <Container
          maxWidth="lg"
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            position: "relative",
            zIndex: 1,
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Typography
              variant="h3"
              sx={{
                color: "white",
                fontSize: { xs: "2rem", md: "3rem" },
                fontWeight: "bold",
                mb: 2,
                textAlign: { xs: "center", md: "left" },
              }}
            >
              Step 2
            </Typography>
            <Typography
              variant="h2"
              sx={{
                color: "white",
                fontSize: { xs: "3rem", md: "5rem" },
                fontWeight: "bold",
                lineHeight: 1.1,
                textAlign: { xs: "center", md: "left" },
                maxWidth: "800px",
              }}
            >
              Share & Connect
            </Typography>
          </motion.div>
        </Container>
      </Box>

      {/* Features Section */}
      <Box
        sx={{
          py: { xs: 8, md: 16 },
          bgcolor: "white",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
              gap: { xs: 3, md: 4 },
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "3rem", md: "4.5rem" },
                  fontWeight: "bold",
                  mb: 12,
                  maxWidth: "600px",
                  lineHeight: 1.1,
                }}
              >
                Your Own
                <br />
                Personal Relationship Manager (PRM)
              </Typography>
            </motion.div>

            {/* Features List */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
              {[
                {
                  title: "Edit Profile",
                  description:
                    "Easily update your profile anytime with just a few clicks—no need to reprint cards.",
                  image: image6,
                  isActive: true,
                },
                {
                  title: "Branding",
                  description:
                    "Customize your digital business card with your brand colors and logo.",
                  image: card1,
                },
                {
                  title: "Interactive Content",
                  description:
                    "Add dynamic content that engages your contacts.",
                  image: nfcTap,
                },
                {
                  title: "Multiple Profiles",
                  description:
                    "Create different profiles for different occasions.",
                  image: personTap,
                },
              ].map((feature, index) => (
                <motion.div
                  key={feature.title}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderLeft: `2px solid ${
                        feature.isActive ? "#FF5733" : "#E0E0E0"
                      }`,
                      pl: 4,
                      py: 3,
                      transition: "all 0.3s ease",
                      "&:hover": {
                        borderLeft: "2px solid #FF5733",
                        backgroundColor: "rgba(255, 87, 51, 0.02)",
                        transform: "translateX(8px)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "600px",
                        transition: "all 0.3s ease",
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "2rem",
                          fontWeight: "bold",
                          mb: 1,
                          transition: "all 0.3s ease",
                        }}
                      >
                        {feature.title}
                      </Typography>
                      {(feature.isActive || feature.description) && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          transition={{ duration: 0.3 }}
                        >
                          <Typography
                            sx={{
                              color: "text.secondary",
                              mb: 2,
                              lineHeight: 1.6,
                            }}
                          >
                            {feature.description}
                          </Typography>
                        </motion.div>
                      )}
                      {feature.isActive && (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 0.2 }}
                        >
                          <Link
                            href="#"
                            sx={{
                              color: "#FF5733",
                              textDecoration: "none",
                              fontWeight: 500,
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              "&:hover": {
                                gap: 1.5,
                              },
                              transition: "all 0.3s ease",
                            }}
                          >
                            Learn More
                            <motion.span
                              whileHover={{ x: 5 }}
                              transition={{ duration: 0.2 }}
                            >
                              →
                            </motion.span>
                          </Link>
                        </motion.div>
                      )}
                    </Box>

                    <Box
                      sx={{
                        position: "relative",
                        overflow: "hidden",
                        borderRadius: "12px",
                        width: "240px",
                        height: "140px",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          transform: "scale(1.05)",
                          boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
                        },
                      }}
                    >
                      <motion.img
                        src={feature.image}
                        alt={feature.title}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        whileHover={{ scale: 1.1 }}
                        transition={{ duration: 0.3 }}
                      />
                    </Box>
                  </Box>
                </motion.div>
              ))}
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Pricing Plans Section */}
      <Box
        sx={{
          py: 16,
          bgcolor: "#f5f5f5",
        }}
      >
        <Container maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "3rem", md: "4.5rem" },
                fontWeight: "bold",
                mb: 8,
                "& .dot": {
                  color: "#FF5733",
                },
              }}
            >
              Plans for Professionals at Every Scale
              <span className="dot">.</span>
            </Typography>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
                gap: 4,
              }}
            >
              {/* Individual Plan */}
              <motion.div whileHover={{ y: -8 }} transition={{ duration: 0.3 }}>
                <Box
                  sx={{
                    bgcolor: "white",
                    borderRadius: 4,
                    p: 4,
                    height: "100%",
                    boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "2.5rem",
                      fontWeight: "bold",
                      color: "#FF5733",
                      mb: 3,
                    }}
                  >
                    Individual
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                      color: "text.secondary",
                      mb: 4,
                      minHeight: "80px",
                    }}
                  >
                    Complete Digital Business Card to help you engage, standout,
                    and grow your network.
                  </Typography>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      bgcolor: "#dc004e",
                      py: 1.5,
                      textTransform: "none",
                      fontSize: "1.1rem",
                      "&:hover": {
                        bgcolor: "#e64a2e",
                      },
                    }}
                  >
                    Try for free
                  </Button>
                </Box>
              </motion.div>

              {/* Teams Plan */}
              <motion.div whileHover={{ y: -8 }} transition={{ duration: 0.3 }}>
                <Box
                  sx={{
                    bgcolor: "white",
                    borderRadius: 4,
                    p: 4,
                    height: "100%",
                    boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "2.5rem",
                      fontWeight: "bold",
                      color: "#8B3DFF", // Purple for Teams
                      mb: 3,
                    }}
                  >
                    Teams
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                      color: "text.secondary",
                      mb: 4,
                      minHeight: "80px",
                    }}
                  >
                    Empower your team to showcase your brand and generate more
                    leads.
                  </Typography>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      bgcolor: "#dc004e",
                      py: 1.5,
                      textTransform: "none",
                      fontSize: "1.1rem",
                      "&:hover": {
                        bgcolor: "#e64a2e",
                      },
                    }}
                  >
                    Try for free
                  </Button>
                </Box>
              </motion.div>

              {/* Enterprise Plan */}
              <motion.div whileHover={{ y: -8 }} transition={{ duration: 0.3 }}>
                <Box
                  sx={{
                    bgcolor: "#1A1A1A",
                    borderRadius: 4,
                    p: 4,
                    height: "100%",
                    boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "2.5rem",
                      fontWeight: "bold",
                      color: "#FFFFFF",
                      mb: 3,
                    }}
                  >
                    Enterprise
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                      color: "rgba(255,255,255,0.7)",
                      mb: 4,
                      minHeight: "80px",
                    }}
                  >
                    Secure, customizable, and integrated solution for large
                    organizations.
                  </Typography>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      bgcolor: "white",
                      color: "#1A1A1A",
                      py: 1.5,
                      textTransform: "none",
                      fontSize: "1.1rem",
                      "&:hover": {
                        bgcolor: "rgba(255,255,255,0.9)",
                      },
                    }}
                  >
                    Talk to Sales
                  </Button>
                </Box>
              </motion.div>
            </Box>
          </motion.div>
        </Container>
      </Box>

      {/* Teams Feature Section */}
      <Box
        sx={{
          py: { xs: 8, md: 16 },
          bgcolor: "white",
          overflow: "hidden",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
              gap: { xs: 6, md: 8 },
              alignItems: "center",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            {/* Left Content */}
            <Box>
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6 }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: "2.5rem", md: "3.5rem" },
                    fontWeight: "bold",
                    mb: 6,
                    lineHeight: 1.2,
                    "& .dot": {
                      color: "#FF5733",
                    },
                  }}
                >
                  Digital Business Card for Teams<span className="dot">.</span>
                </Typography>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
                  {[
                    { text: "Consistent Branding", icon: "⭕" },
                    { text: "Company Directory", icon: "⭕" },
                    { text: "Team Analytics", icon: "⭕" },
                    { text: "Permission Control", icon: "⭕" },
                    { text: "Lead Capture", icon: "⭕" },
                    { text: "CRM Integration", icon: "⭕" },
                  ].map((feature, index) => (
                    <motion.div
                      key={feature.text}
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <Box
                          sx={{
                            color: "#FF5733",
                            fontSize: "1.2rem",
                          }}
                        >
                          {feature.icon}
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "1.1rem",
                            color: "text.primary",
                          }}
                        >
                          {feature.text}
                        </Typography>
                      </Box>
                    </motion.div>
                  ))}
                </Box>

                <Button
                  variant="contained"
                  sx={{
                    mt: 6,
                    bgcolor: "#dc004e",
                    color: "white",
                    textTransform: "none",
                    fontSize: "1.1rem",
                    px: 4,
                    py: 1.5,
                    borderRadius: 2,
                    "&:hover": {
                      bgcolor: "#e64a2e",
                    },
                  }}
                >
                  Explore Bappa Enterprise
                </Button>
              </motion.div>
            </Box>

            {/* Right Image */}
            <Box sx={{ position: "relative" }}>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6 }}
              >
                <Box
                  component="img"
                  src={handshake}
                  sx={{
                    width: "120%",
                    height: "auto",
                    transform: "perspective(1000px) rotateY(-15deg)",
                    filter: "drop-shadow(0 20px 40px rgba(0,0,0,0.15))",
                  }}
                  alt="OVOU Team Cards"
                />
              </motion.div>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Lander;
