import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  Box,
  Link,
  IconButton,
  Drawer,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import bappaLogo from "../../images/bappa-logo.png";

const LegalPage = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backdropFilter: isScrolled ? "blur(10px)" : "none",
          transition: "all 0.3s ease",
          borderBottom: isScrolled
            ? "1px solid rgba(255, 255, 255, 0.1)"
            : "none",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              justifyContent: "space-between",
              height: { xs: "64px", md: "80px" },
              transition: "all 0.3s ease",
              px: { xs: 2, sm: 3, md: 4 },
            }}
          >
            {/* Logo */}
            <Link
              href="/"
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 0,
              }}
            >
              <img
                src={bappaLogo}
                alt="BappaCards"
                style={{
                  height: "24px",
                  width: "auto",
                }}
              />
            </Link>

            {/* Center Navigation */}
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 4,
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
              }}
            >
              <Link
                href="/teams"
                sx={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1rem",
                  fontWeight: 400,
                  opacity: 0.9,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                For Teams
              </Link>

              <Link
                href="/products"
                sx={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1rem",
                  fontWeight: 400,
                  opacity: 0.9,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                Products
              </Link>

              <Link
                href="/pricing"
                sx={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1rem",
                  fontWeight: 400,
                  opacity: 0.9,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                Pricing
              </Link>
            </Box>

            {/* Right Side Buttons */}
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                flexGrow: 0,
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <Link
                  href="/log-in"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "1rem",
                    fontWeight: 400,
                    opacity: 0.9,
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                >
                  Login
                </Link>
                <Button
                  variant="contained"
                  href="/get-started"
                  sx={{
                    bgcolor: "#dc004e",
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: 400,
                    px: 3,
                    py: 1,
                    borderRadius: "8px",
                    "&:hover": {
                      bgcolor: "#0f0f23",
                    },
                  }}
                >
                  Get Started
                </Button>
              </Box>

              {/* Mobile Menu Button */}
              <IconButton
                sx={{
                  display: { xs: "flex", md: "none" },
                  color: "white",
                }}
                onClick={() => setMobileMenuOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Mobile Menu Drawer */}
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        PaperProps={{
          sx: {
            width: "100%",
            bgcolor: "rgba(0, 0, 0, 0.95)",
            backdropFilter: "blur(10px)",
            color: "white",
          },
        }}
      >
        {/* Add mobile menu content here if needed */}
      </Drawer>

      {/* Existing Legal Page Content with adjusted padding for navbar */}
      <Container
        maxWidth="md"
        style={{ marginTop: "100px", marginBottom: "20px" }}
      >
        <Typography variant="h4" gutterBottom>
          Privacy Notice & Terms and Conditions
        </Typography>

        {/* Privacy Notice Section */}
        <Typography variant="h5" gutterBottom>
          Privacy Notice
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Effective Date:</strong> January 1st, 2022 <br />
          <strong>Last Updated:</strong> December 4th, 2024
        </Typography>

        <Typography variant="body1" paragraph>
          Welcome to BappaCards, a digital business card platform. We are
          committed to protecting your personal information and respecting your
          privacy. This Privacy Notice explains how we collect, use, and protect
          your information when you use our services.
        </Typography>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Information We Collect</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              We may collect and store the following personal information when
              you use our platform:
              <ul>
                <li>First Name and Last Name</li>
                <li>Phone Number</li>
                <li>Email Address</li>
                <li>Social Media Links (e.g., LinkedIn, Twitter, etc.)</li>
                <li>
                  Any additional information you choose to include on your
                  digital business card
                </li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">How We Use Your Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              We collect this information to:
              <ol>
                <li>
                  Provide you with our services, including the creation,
                  storage, and sharing of digital business cards.
                </li>
                <li>
                  Display your information on digital business cards you choose
                  to share.
                </li>
                <li>
                  Improve and customize our services to better meet your needs.
                </li>
                <li>
                  Communicate with you about your account, updates, or changes
                  to our services.
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Add other sections of Privacy Notice similarly */}

        {/* Terms and Conditions Section */}
        <Typography variant="h5" gutterBottom style={{ marginTop: "20px" }}>
          Terms and Conditions
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Effective Date:</strong> January 1st, 2022 <br />
          <strong>Last Updated:</strong> December 4th, 2024
        </Typography>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Acceptance of Terms</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              By using BappaCards, you agree to these Terms and Conditions. If
              you do not agree, please do not use our platform.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Use of the Platform</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1">
              <ol>
                <li>
                  <strong>Account Responsibility:</strong> You are responsible
                  for maintaining the confidentiality of your account and
                  ensuring the information you provide is accurate and
                  up-to-date.
                </li>
                <li>
                  <strong>Content Ownership and License:</strong> You retain
                  ownership of the personal information you upload. By using
                  BappaCards, you grant us a non-exclusive license to store,
                  display, and share this information as part of your digital
                  business card.
                </li>
                <li>
                  <strong>Prohibited Uses:</strong> You agree not to use
                  BappaCards for unlawful purposes or to upload any offensive or
                  harmful content.
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* Add other sections of Terms and Conditions similarly */}
      </Container>
    </>
  );
};

export default LegalPage;
