import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Container,
  Chip,
  AppBar,
  Toolbar,
  Link,
  IconButton,
  Drawer,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CheckIcon from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import bappaLogo from "../../images/bappa-logo.png"; // Make sure to import your logo

// Custom styled components
const StyledCard = styled(Card)(({ theme, featured }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  transition: "transform 0.2s ease-in-out",
  backgroundColor: featured ? "#0f0f23" : "white",
  color: featured ? "white" : "inherit",
  "&:hover": {
    transform: "translateY(-8px)",
  },
  borderRadius: "16px",
  boxShadow: featured
    ? "0 4px 20px rgba(0,0,0,0.15)"
    : "0 4px 20px rgba(0,0,0,0.08)",
}));

const SubscriptionsPage = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const plans = [
    {
      title: "Bappa Standard",
      price: "Free",
      period: "forever",
      description:
        "For individuals getting started with digital business cards",
      buttonText: "Get Started Now",
      features: [
        "1 Digital Business Card",
        "Basic Customization",
        "Share via Link or QR Code",
        "Basic Analytics",
        "Standard Support",
      ],
    },
    {
      title: "Bappa Premium",
      price: "$5",
      period: "per month",
      description: "For professionals who need advanced features",
      buttonText: "Start 14 Day Free Trial",
      featured: true,
      features: [
        "Multiple Business Cards",
        "Advanced Customization",
        "Custom Branding",
        "Detailed Analytics",
        "Priority Support",
      ],
    },
    {
      title: "Bappa Enterprise",
      price: "Contact Us",
      period: "for custom pricing",
      description: "For organizations requiring custom solutions",
      buttonText: "Contact Sales",
      features: [
        "Unlimited Business Cards",
        "Custom Integration",
        "Team Management",
        "Advanced Security",
        "Dedicated Support",
      ],
    },
  ];

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backdropFilter: isScrolled ? "blur(10px)" : "none",
          transition: "all 0.3s ease",
          borderBottom: isScrolled
            ? "1px solid rgba(255, 255, 255, 0.1)"
            : "none",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            sx={{
              justifyContent: "space-between",
              height: { xs: "64px", md: "80px" },
              transition: "all 0.3s ease",
              px: { xs: 2, sm: 3, md: 4 },
            }}
          >
            {/* Logo */}
            <Link
              href="/"
              sx={{
                display: "flex",
                alignItems: "center",
                flexGrow: 0,
              }}
            >
              <img
                src={bappaLogo}
                alt="BappaCards"
                style={{
                  height: "24px",
                  width: "auto",
                }}
              />
            </Link>

            {/* Center Navigation */}
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 4,
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
              }}
            >
              <Link
                href="/teams"
                sx={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1rem",
                  fontWeight: 400,
                  opacity: 0.9,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                For Teams
              </Link>

              <Link
                href="/products"
                sx={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1rem",
                  fontWeight: 400,
                  opacity: 0.9,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                Products
              </Link>

              <Link
                href="/pricing"
                sx={{
                  color: "white",
                  textDecoration: "none",
                  fontSize: "1rem",
                  fontWeight: 400,
                  opacity: 0.9,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              >
                Pricing
              </Link>
            </Box>

            {/* Right Side Buttons */}
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                flexGrow: 0,
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <Link
                  href="/log-in"
                  sx={{
                    color: "white",
                    textDecoration: "none",
                    fontSize: "1rem",
                    fontWeight: 400,
                    opacity: 0.9,
                    "&:hover": {
                      opacity: 1,
                    },
                  }}
                >
                  Login
                </Link>
                <Button
                  variant="contained"
                  href="/get-started"
                  sx={{
                    bgcolor: "#dc004e",
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: 400,
                    px: 3,
                    py: 1,
                    borderRadius: "8px",
                    "&:hover": {
                      bgcolor: "#0f0f23",
                    },
                  }}
                >
                  Get Started
                </Button>
              </Box>

              {/* Mobile Menu Button */}
              <IconButton
                sx={{
                  display: { xs: "flex", md: "none" },
                  color: "white",
                }}
                onClick={() => setMobileMenuOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Mobile Menu Drawer */}
      <Drawer
        anchor="right"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        PaperProps={{
          sx: {
            width: "100%",
            bgcolor: "rgba(0, 0, 0, 0.95)",
            backdropFilter: "blur(10px)",
            color: "white",
          },
        }}
      >
        {/* Mobile Menu Content */}
        <Box sx={{ p: 4 }}>
          <Box sx={{ mb: 4 }}>
            <img
              src={bappaLogo}
              alt="BappaCards"
              style={{
                height: "24px",
                width: "auto",
              }}
            />
          </Box>

          {/* Mobile Navigation Links */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <Link
              href="/teams"
              sx={{
                color: "white",
                textDecoration: "none",
                fontSize: "1.2rem",
                fontWeight: 400,
              }}
            >
              For Teams
            </Link>
            <Link
              href="/products"
              sx={{
                color: "white",
                textDecoration: "none",
                fontSize: "1.2rem",
                fontWeight: 400,
              }}
            >
              Products
            </Link>
            <Link
              href="/pricing"
              sx={{
                color: "white",
                textDecoration: "none",
                fontSize: "1.2rem",
                fontWeight: 400,
              }}
            >
              Pricing
            </Link>
            <Link
              href="/log-in"
              sx={{
                color: "white",
                textDecoration: "none",
                fontSize: "1.2rem",
                fontWeight: 400,
              }}
            >
              Login
            </Link>
          </Box>

          <Button
            variant="contained"
            fullWidth
            sx={{
              mt: 4,
              bgcolor: "#dc004e",
              textTransform: "none",
              fontSize: "1.1rem",
              py: 1.5,
              "&:hover": {
                bgcolor: "#0f0f23",
              },
            }}
          >
            Get Started
          </Button>
        </Box>
      </Drawer>

      {/* Your existing pricing content with adjusted top padding */}
      <Box sx={{ bgcolor: "#f5f5f5", pt: { xs: "80px", md: "100px" }, pb: 16 }}>
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: "3rem", md: "4.5rem" },
              fontWeight: "bold",
              mb: 8,
              "& .dot": {
                color: "#dc004e",
              },
            }}
          >
            Plans for Professionals at Every Scale
            <span className="dot">.</span>
          </Typography>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
              gap: 4,
            }}
          >
            {plans.map((plan) => (
              <StyledCard key={plan.title} featured={plan.featured}>
                {plan.featured && (
                  <Chip
                    label="Most Popular"
                    color="primary"
                    sx={{
                      position: "absolute",
                      top: 16,
                      right: 24,
                      px: 1,
                      height: "24px",
                      bgcolor: "#dc004e",
                      "& .MuiChip-label": {
                        px: 2,
                      },
                      zIndex: 1,
                    }}
                  />
                )}
                <CardContent
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    p: 4,
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: "2.5rem",
                      fontWeight: "bold",
                      color: plan.featured ? "white" : "#dc004e",
                      mb: 3,
                    }}
                  >
                    {plan.title}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "1.1rem",
                      color: plan.featured
                        ? "rgba(255,255,255,0.7)"
                        : "text.secondary",
                      mb: 4,
                      minHeight: "80px",
                    }}
                  >
                    {plan.description}
                  </Typography>

                  <Box sx={{ textAlign: "center", mb: 4 }}>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: "2.5rem",
                        fontWeight: "bold",
                        color: plan.featured ? "white" : "inherit",
                      }}
                    >
                      {plan.price}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: plan.featured
                          ? "rgba(255,255,255,0.7)"
                          : "text.secondary",
                      }}
                    >
                      {plan.period}
                    </Typography>
                  </Box>

                  <Button
                    variant="contained"
                    fullWidth
                    sx={{
                      bgcolor: plan.featured ? "white" : "#dc004e",
                      color: plan.featured ? "#1A1A1A" : "white",
                      py: 1.5,
                      textTransform: "none",
                      fontSize: "1.1rem",
                      mb: 4,
                      "&:hover": {
                        bgcolor: plan.featured
                          ? "rgba(255,255,255,0.9)"
                          : "#e64a2e",
                      },
                    }}
                  >
                    {plan.buttonText}
                  </Button>

                  <Box sx={{ mt: "auto" }}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        mb: 2,
                        color: plan.featured ? "white" : "inherit",
                      }}
                    >
                      {plan === plans[1]
                        ? "Everything in Standard, plus:"
                        : "Features include:"}
                    </Typography>
                    {plan.features.map((feature) => (
                      <Box
                        key={feature}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          mb: 2,
                        }}
                      >
                        <CheckIcon
                          sx={{
                            color: plan.featured ? "white" : "#dc004e",
                            fontSize: "1.2rem",
                          }}
                        />
                        <Typography
                          sx={{
                            color: plan.featured
                              ? "rgba(255,255,255,0.7)"
                              : "text.secondary",
                          }}
                        >
                          {feature}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </CardContent>
              </StyledCard>
            ))}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SubscriptionsPage;
