import React, { useCallback } from "react";
import { InfoSection, Pricing } from "../../components";
import { homeObjOne, homeObjTwo, homeObjThree } from "./Data";
import {
  AppBar,
  Toolbar,
  Button,
  Container,
  Menu,
  MenuItem,
  Box,
  Typography,
  Link,
  IconButton,
  Drawer,
} from "@mui/material";

function Products() {
  return (
    <>
      {/* <Pricing /> */}
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjTwo} />
      <InfoSection {...homeObjThree} />
    </>
  );
}

export default Products;
